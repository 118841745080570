<template>
  <el-form
    class="form"
    ref="form"
    :model="form"
    :rules="rules"
    label-width="210px"
  >
    <el-form-item label="产品型号：" prop="productModel">
      <el-input
        v-model="form.productModel"
        style="width: 300px"
        placeholder="请输入产品型号"
        :maxlength="25"
      ></el-input>
    </el-form-item>
    <el-form-item label="产品类型：" prop="productType">
      <el-select
        v-model="form.productType"
        style="width: 300px"
        @change="changeType"
        placeholder="请选择产品类型"
      >
        <el-option
          v-for="opt in PRODUCT_TYPE"
          :label="opt.label"
          :key="opt.value"
          :value="opt.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <div v-if="form.productType == 1">
      <el-form-item label="电压规格(V)：" prop="electricVoltage">
        <el-input
          v-model="form.electricVoltage"
          style="width: 300px"
          placeholder="请输入电压规格"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="电池容量(Ah)：" prop="batteryCapacity">
        <el-input
          v-model="form.batteryCapacity"
          style="width: 300px"
          placeholder="请输入电池容量"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="电池材料："  prop="batteryMaterialType">
        <el-select
          v-model="form.batteryMaterialType"
          style="width: 300px"
          placeholder="请选择BMS板"
        >
          <el-option
            v-for="opt in BATTERY_MATERIAL"
            :label="opt.label"
            :key="opt.value"
            :value="opt.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="BMS板："  prop="bmsBoardType">
        <el-select
          v-model="form.bmsBoardType"
          style="width: 300px"
          placeholder="请选择BMS板"
        >
          <el-option
            v-for="opt in BMS_BOARD_TYPE"
            :label="opt.label"
            :key="opt.value"
            :value="opt.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="BMS供应商："  prop="bmsSupplierNo">
        <el-select
          v-model="form.bmsSupplierNo"
          style="width: 300px"
          placeholder="请选择BMS供应商"
        >
          <el-option
            v-for="opt in bmsList"
            :label="opt.name"
            :key="opt.number"
            :value="opt.number"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="GPS供应商：" prop="gpsSupplierNo">
        <el-select
          v-model="form.gpsSupplierNo"
          style="width: 300px"
          placeholder="请选择GPS供应商"
        >
          <el-option
            v-for="opt in gpsList"
            :label="opt.name"
            :key="opt.number"
            :value="opt.number"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="PACK出品方：" prop="packProducer">
        <el-input
          v-model="form.packProducer"
          style="width: 300px"
          placeholder="请输入PACK出品方"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="电芯供应商：" prop="cellSupplier">
        <el-input
          v-model="form.cellSupplier"
          style="width: 300px"
          placeholder="请输入电芯供应商"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="生产厂商：" prop="manufacturerName">
        <el-input
          v-model="form.manufacturerName"
          style="width: 300px"
          placeholder="请输入生产厂商"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="生产厂商统一社会信用代码："
        prop="manufacturerSocialCreditCode"
      >
        <el-input
          v-model="form.manufacturerSocialCreditCode"
          style="width: 300px"
          placeholder="请输入生产厂商统一社会信用代码"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="资产所有人名称：" prop="assetOwner">
        <el-input
          v-model="form.assetOwner"
          style="width: 300px"
          placeholder="请输入资产所有人"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="资产所有人统一社会信用代码："
        prop="assetOwnerSocialCreditCode"
      >
        <el-input
          v-model="form.assetOwnerSocialCreditCode"
          style="width: 300px"
          placeholder="请输入资产所有人统一社会信用代码"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="电池单价：" prop="batteryUnitPrice">
        <el-input
          v-model="form.batteryUnitPrice"
          style="width: 300px"
          placeholder="请输入电池单价"
          :maxlength="25"
        ></el-input>
      </el-form-item>
    </div>
    <div v-else>
      <el-form-item label="车辆规格：" prop="vehicleSpecification">
        <el-input
          v-model="form.vehicleSpecification"
          style="width: 300px"
          placeholder="请输入车辆规格"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="车辆品牌：" prop="vehicleBrand">
        <el-input
          v-model="form.vehicleBrand"
          style="width: 300px"
          placeholder="请输入车辆品牌"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="车辆型号："  prop="vehicleModel">
        <el-input
          v-model="form.vehicleModel"
          style="width: 300px"
          placeholder="请输入车辆型号"
          :maxlength="25"
        ></el-input>
      </el-form-item>
    </div>
    <el-form-item class="mt20">
      <el-button type="primary" @click="submitForm" :loading="disaled">确认</el-button>
      <el-button type="info" @click="goBack">返回</el-button>
    </el-form-item>
  </el-form>
  
</template>

<script>
import { PRODUCT_TYPE, BMS_BOARD_TYPE, BATTERY_MATERIAL } from "@/js/constant";
import { addProduct, getSupplierList, getProductDetail } from "@/api/product";
import Reg from "@/js/reg";
export default {
  data() {
    const checkProductModel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入产品型号'));
      } else if (Reg.isCharacter(value)) {
        callback(new Error('请输入正确产品型号!'));
      } else {
        callback();
      }
    }
    const checkNum = (rule, value, callback) => {
      if (!Reg.integerZ(value)) {
        callback(new Error('请输入正确数字!'));
      } else {
        callback();
      }
    }
    const checkNum2 = (rule, value, callback) => {
      if (value == '') {
        callback();
      } else if (!Reg.isDigit(value)) {
        callback(new Error('请输入正确价格!'));
      } else {
        callback();
      }
    }
    return {
      form: {
        productModel: "" /** 产品型号 */,
        productType: 1 /** 产品类型 */,
        electricVoltage: "", // 电压规格
        batteryCapacity: "", // 电池容量
        batteryMaterial: "", // 电池材料
        batteryMaterialType: 1,
        bmsBoardType: '', // bms板类型
        bmsSupplierNo: "",
        gpsSupplierNo: "",
        packProducer: "",
        cellSupplier: "",
        manufacturerName: "",
        manufacturerSocialCreditCode: "",
        assetOwner: "",
        assetOwnerSocialCreditCode: "",
        batteryUnitPrice: "",
        vehicleSpecification: '',
        vehicleBrand: '',
        vehicleModel: '',
      },
      rules: {
        productModel: [
          { required: true, message: '请输入产品型号', trigger: 'blur' },
          { validator: checkProductModel, trigger: 'blur' }
        ],
        productType: [
          { required: true, message: '请选择产品类型', trigger: 'change' },
        ],
        electricVoltage: [
          { required: true, message: "请输入电压规格", trigger: "blur" },
          { validator: checkNum, trigger: 'blur' }
        ],
        bmsBoardType: [
          { required: true, message: "请选择BMS板", trigger: "change" },
        ],
        batteryCapacity: [
          { required: true, message: "请输入电池容量", trigger: "blur" },
          { validator: checkNum, trigger: 'blur' }
        ],
        batteryMaterialType: [
          { required: true, message: "请输入电池材料", trigger: "change" },
        ],
        bmsSupplierNo: [
          { required: true, message: "请选择BMS供应商", trigger: "change" },
        ],
        gpsSupplierNo: [
          { required: true, message: "请选择GPS供应商", trigger: "change" },
        ],
        batteryUnitPrice: [
          { validator: checkNum2, trigger: 'blur' }
        ]
      },
      disaled: false,
      PRODUCT_TYPE,
      BMS_BOARD_TYPE,
      bmsList: [],
      gpsList: [],
      productNo: '',
      BATTERY_MATERIAL,
    };
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      this.productNo = this.$route.query.productNo
      this.getProductDetail()
    }
    this.getSupplierList();
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.disaled = true;
          const params = {
            ...this.form,
          };
          /** 编辑 */
          if (this.productNo) {
            params.productNo = this.productNo;
          }
          try {
            await addProduct(params);
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/library");
            }, 1000);
          } catch (error) {
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    async getSupplierList() {
      const params = {
        pageSize: 500,
        beginIndex: 0,
      };
      const res = await getSupplierList(params);
      res.ldata.forEach(el => {
        if (el.subType == 1) {
          this.gpsList.push(el);
        }
        if (el.subType == 2) {
          this.bmsList.push(el);
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/library');

    },
    changeType() {
      this.$nextTick(() => {
         this.$refs.form.clearValidate()
      })
    },
    /** 获取产品详情 */
    async getProductDetail() {
      const res = await getProductDetail({productNo: this.productNo});
      this.form = res.mdata.product
      if (res.mdata.product.batteryMaterialType) {
          this.form.batteryMaterialType = + res.mdata.product.batteryMaterialType 
      }
  
    }
  },
  components: {}
};
</script>

<style scoped></style>
